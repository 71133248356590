<template>
  <div>
    <section class="page-top-section" :style="{backgroundImage:'url(' + $imgURL + contatotopo[0].insInstitucionalImagens[0].itgUrlImagem + ')'}" v-if="contatotopo!=''">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h2  id="h2TituloTopo">{{ contatotopo[0].itiNome}}</h2>
              <p  id="pTextoTopo" v-html="contatotopo[0].itiReferencia"></p>
            </div>
          </div>
        </div>
    </section>
        <!-- Contact section   -->
        <section class="contact-section spad" v-if="contato!=''">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="contact-text">
                        <h2>{{contato[0].itiNome}}</h2>
                        <p id="pAtendimento" v-html="contato[0].itiResumo1"></p>
                        <div class="header-info-box">
                            <div class="hib-icon">
                                <img src="/img/icons/phone.png" alt="" class="">
                            </div>
                            <div class="hib-text">
                                <p  id="pContato" v-html="contato[0].itiResumo2">
                                </p>
                            </div>
                        </div>
                        <div class="header-info-box">
                            <div class="hib-icon">
                                <img src="/img/icons/map-marker.png" alt="" class="">
                            </div>
                            <div class="hib-text" id="divEndereco" v-html="contato[0].itiDescricao1">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="contact-form">
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" v-model="Nome"  id="txtNome" placeholder="Seu Nome"/>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" v-model="Email" id="txtEmail" placeholder="Seu E-mail"/>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" v-model="Telefone" id="txtFone" placeholder="Seu Telefone"/>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" v-model="Assunto" id="txtAssunto" placeholder="Assunto"/>
                            </div>
                            <div class="col-lg-12">
                                <textarea class="text-msg" v-model="Mensagem" id="txtMensagem" placeholder="Mensagem"></textarea>
                                <button class="site-btn" @click="EnviaEmail()" style="color:#ffffff">Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          <!-- Map section  -->
    <div class="map-section" style="height: 500px;position: relative;">
      <iframe :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + contato[0].itiCampoExtra1 "
              width="100%" height="500px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0">
      </iframe>
    </div>
    <!-- Map section end  -->
    </section>
    <!-- Contact section end  -->
  
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';

export default {
  name: 'ContatoView',
  components: {
  },
  data: () => ({
    contatotopo: [],
    contato:[],
    Nome:'',
    Email:'',
    Telefone:'',
    Assunto:'',
    Mensagem:'',
  }),
  methods: {
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Top Informática",
          assunto: 'Contato do Site Top Informática ' + this.Assunto,
          destinatario: this.contato[0].itiReferencia,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Assunto = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  created() {
    axios.get('/Process/InsInstitucional/institucional/25/contatotopo')
      .then(response => {
        this.contatotopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/26/contato')
      .then(response => {
        this.contato = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
