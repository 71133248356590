<template>
  <div>
    <section class="page-top-section"
      :style="{ backgroundImage: 'url(' + $imgURL + logintopo[0].insInstitucionalImagens[0].itgUrlImagem + ')' }"
      v-if="logintopo != ''">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2 id="h2TituloTopo">{{ logintopo[0].itiNome }}</h2>
            <p id="pTextoTopo" v-html="logintopo[0].itiReferencia"></p>
          </div>
        </div>
      </div>
    </section>
    <!-- Page top section end  -->
    <!-- Contact section   -->
    <section class="contact-section spad" v-if="areaCliente!=''">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="contact-text">
              <h2 id="h2Titulo">{{  areaCliente[0].itiNome }}</h2>
              <p id="pTexto">{{ areaCliente[0].itiReferencia }}</p>
              <div class="header-info-box">
                <div class="hib-icon">
                  <img src="/img/icons/phone.png" alt="" class="">
                </div>
                <div class="hib-text">
                  <p id="pTexto2" v-html="areaCliente[0].itiResumo1"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="contact-form">
              <div class="row">
                <div class="col-lg-12">
                  <input type="text" v-model="usuario" id="txtEmail" placeholder="Usuário" />
                </div>
                <div class="col-lg-12">
                  <input type="password" v-model="senha" id="txtSenha" placeholder="Senha" />
                </div>
                <div class="col-lg-12">
                  <!-- <div style="font-size: 10px; color: #000000; text-align: right;cursor:pointer" onclick="location.href='/esqueci-senha'">Esqueceu a senha?</div> -->
                  <br />
                  <button @click="ValidaLogin()" style="color:#ffffff" class="site-btn">Acessar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { SHA1,SHA256 } from 'crypto-js';

export default {
  name: 'LoginView',
  components: {

  },
  data: () => ({
    logintopo: [],
    areaCliente:[],
    ListClientes:[],
    usuario:'',
    senha:'',
  }),
  methods: {
    ValidaLogin(){
      axios.get(`/Process/CliClientes/GetAll`)
        .then(response => {
          this.ListClientes = response.data.filter(x=> x.cliEmail.toLowerCase() == this.usuario.toLowerCase()); 
          if (this.ListClientes != '')
        {
          let originalHash = this.ListClientes[0].cliSenha;
          const valueToCompare = this.senha;
          const compareHash = SHA1(valueToCompare).toString();

          const valueToCompare2 = this.senha;
          const compareHash2 = SHA256(valueToCompare).toString();

          if (originalHash != compareHash) {
            if (originalHash != compareHash2){
               this.$mensagemErro("Senha não autorizada.")
               this.$router.push('/Login')
            }
            else{
              this.$Cookies.set('_topinfcha', this.ListClientes[0].cliNomeEmpresa, this.$CookiesDefaults);
              this.$router.push('/Chamado')
            }
          }
          else{
            this.$Cookies.set('_topinfcha', this.ListClientes[0].cliNomeEmpresa, this.$CookiesDefaults);
            this.$router.push('/Chamado')
          }
        }
        else{
          this.$mensagemErro("Usuário Inválido.")
        }
        });
    },
  },
  created() {
    axios.get('/Process/InsInstitucional/institucional/27/areaclientetopo')
      .then(response => {
        this.logintopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/28/areacliente')
      .then(response => {
        this.areaCliente = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
