<template>
  <div>
    <!-- Aqui vai menu computador-->

    <div class="hidden-sm hidden-xs">
      <header class="header-section clearfix x" id="myHeader" style="background-color:white;" v-if="topo!=''">
        <div class="header-top">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <p runat="server" id="pEsquerda">{{ topo[0].itiNome }}</p>
              </div>
              <div class="col-md-6 text-md-right">
                <p runat="server" id="pDireita">{{ topo[0].itiReferencia }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="site-navbar">
          <!-- Logo -->
          <a href="/" class="site-logo">
            <img src="/img/logo.png" style="height: 80px; width: auto;" alt="">
          </a>
          <div class="header-right">
            <div class="header-info-box">
              <div class="hib-icon">
                <img src="/img/icons/phone.png" alt="" class="">
              </div>
              <div class="hib-text">
                <h6 runat="server" id="hTelefone">{{ topo[0].itiCampoExtra1 }} | <a :href="topo[0].itiCampoExtra3" target="_blank" style="color:#333333;">{{ topo[0].itiCampoExtra2 }}</a></h6>
                <p runat="server" id="pEmail">{{ topo[0].itiCampoExtra4 }}</p>
              </div>
            </div>
            <button class="search-switch"><i class="fa fa-search"></i></button>
          </div>
          <nav class="navbar navbar-expand-lg ftco-navbar-light site-nav-menu" id="ftco-navbar">
              <div class="container d-flex align-items-center">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
                  aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="oi oi-menu"></span> Menu
                </button>
                <div class="collapse navbar-collapse" id="ftco-nav">
                  <ul class="navbar-nav mr-auto">
                    <li class="nav-item" style="padding-right: 10px"><router-link to="/" class="nav-link">Home</router-link></li>
                    <li class="nav-item" style="padding-right: 10px"><router-link to="/sobre" class="nav-link">Sobre</router-link></li>
                    <li class="nav-item" style="padding-right: 10px"><router-link to="/servicos" class="nav-link">Serviços</router-link></li>
                    <li class="nav-item" style="padding-right: 10px"><router-link to="/blog" class="nav-link">Blog</router-link></li>
                    <li class="nav-item" style="padding-right: 10px"><router-link to="/contato" class="nav-link">Contato</router-link></li>
                    <li class="nav-item" style="padding-right: 10px"><router-link to="/login" class="nav-link">Área do Cliente</router-link></li>
                  </ul>
                </div>
              </div>
            </nav>
        </div>
      </header>

    </div>

    <!-- Aqui vai menu para celular-->
    <div class="hidden-md hidden-lg">
      <div class="container" style="padding-bottom: 15px;">
        <div class="row">
          <div class="col-4" style="padding-top: 15px;">
            <v-menu width="300" style="padding-left: 20px;">
              <template v-slot:activator="{ props }">
                <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <router-link to="/">
                    <v-list-item-title class="menuTamanho">Home</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/sobre">
                    <v-list-item-title class="menuTamanho">Sobre</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/servicos">
                    <v-list-item-title class="menuTamanho">Serviços</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/blog">
                    <v-list-item-title class="menuTamanho">Blog</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/contato">
                    <v-list-item-title class="menuTamanho">Contato</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/login">
                    <v-list-item-title class="menuTamanho">Área do Cliente</v-list-item-title>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="col-8" style="display:flex; align-items: end; justify-content: end;">
            <router-link to="/" class="navbar-brand">
              <img src="/img/logo.png" /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageHeader',
  components: {

  },
  data: () => ({
    topo: [],
  }),
  methods: {

  },
  created() {  
    axios.get('/Process/InsInstitucional/institucional/9/cabecalho')
         .then(response => {
         this.topo = response.data;
       })
       .catch(error => {
         console.error(error);
       });
  }
}
</script>

<style scoped>
.menuTamanho {
  font-size: 20px;
  text-align: center;
}
</style>
