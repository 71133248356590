<template>
  <v-app>
    <v-main>
      <Loader v-if="$loading.value"></Loader>
      <Header />
      <router-view :key="$route.fullPath" />
      <FooterPage />
    </v-main>
  </v-app>
</template>

<script>

import Header from './components/PageHeader.vue'
import FooterPage from './components/PageFooter.vue'
import Loader from './components/PageLoader.vue'

export default {
  name: 'App',
  components: {
    Header,
    FooterPage,
    Loader
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
}
</script>
<style>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/magnific-popup.css";
@import "@/assets/css/slicknav.min.css";
@import "@/assets/css/style.css";

@media (max-width:767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width:1200px) {
  .hidden-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

  }

  .sticky .site-navbar {
    background-color: white;
    margin: 0px 9vh 0px;
  }

  .sticky .site-nav-menu ul li a {
    font-size: 12px;
  }

  .sticky+.content {
    padding-top: 137px;
  }

  #myHeader * {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }

  #myHeader {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}

 /* WHATSAPP FIXO */
 .whatsapp-fixo {
      position: fixed;
      bottom: 10px;
      right: 5px;
      z-index: 999;
  }
</style>
