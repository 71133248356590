<template>
  <div>
    <section class="page-top-section" :style="{backgroundImage:'url(' + $imgURL + servicotopo[0].insInstitucionalImagens[0].itgUrlImagem + ')'}" v-if="servicotopo!=''">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h2  id="h2TituloTopo">{{ servicotopo[0].itiNome}}</h2>
              <p  id="pTextoTopo" v-html="servicotopo[0].itiReferencia"></p>
              <a :href="servicotopo[0].itiLinkBtn1" class="site-btn" style="color:#FFFFFF;">{{servicotopo[0].itiTextoBtn1}}</a>
            </div>
          </div>
        </div>
    </section>
    <!-- About section -->
    <section class="about-section spad" v-if="servicotopo!=''">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-text">
              <h2  id="h2Titulo">{{ servicotopo[0].itiCampoExtra1 }}</h2>
              <p  id="pTexto" v-html="servicotopo[0].itiResumo1"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- About section end -->
    <!-- Reserch section  -->
    <section class="reserch-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <ul class="nav nav-tabs reserch-tab-menu" role="tablist">
              <li class="nav-item" v-for="menu in servicos" :key="menu.itiId">
                <a class="nav-link" href="#" @click.prevent="carregaTexto(menu.itiId)">{{ menu.itiNome }}</a>
              </li>
            </ul>
            <div class="reserch-info-warp hidden-xs hidden-sm">
              <!-- <a href="/login" class="site-btn w-100">Abrir Chamado</a> -->
              <div class="info-box sli" v-if="servicocall!=''">
                <h3>{{servicocall[0].itiNome}}</h3>
                <p  id="pTextoSuporte" v-html="servicocall[0].itiResumo1"></p>
                <div class="footer-info-box">
                  <div class="fib-icon">
                    <img src="/img/icons/phone.png" alt="" class="">
                  </div>
                  <div class="fib-text sli">
                    <p  id="pTextoSuporteBaixo" v-html="servicocall[0].itiResumo2">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="tab-content" v-if="servico!=''">
              <!-- single tab content -->
                  <div class="sli">
                        <h2 style="color:#99CC33;">{{ servico[0].itiNome}}</h2>
                        <p v-html="servico[0].itiResumo1"></p>
                  </div>
            </div>
            <div class="reserch-info-warp hidden-lg hidden-md">
              <!-- <a href="/login" class="site-btn w-100">Abrir Chamado</a> -->
              <div class="info-box sli" v-if="servicocall!=''">
                <h3>{{servicocall[0].itiNome}}</h3>
                <p  id="pTextoSuporte" v-html="servicocall[0].itiResumo1"></p>
                <div class="footer-info-box">
                  <div class="fib-icon">
                    <img src="/img/icons/phone.png" alt="" class="">
                  </div>
                  <div class="fib-text sli">
                    <p  id="pTextoSuporteBaixo" v-html="servicocall[0].itiResumo2">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Reserch section end  -->
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'ServicosView',
  setup() {
    const route = useRoute();
    const id = route.params.idServico;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    servicotopo: [],
    servicos: [],
    servico:[],
    servicocall:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Serviços - Top Informática'
			})
		},
    carregaTexto(value){
      this.servico = this.servicos.filter(x=> x.itiId == value)
    },
  },
  created() {

    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/20/servicostopo')
      .then(response => {
        this.servicotopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/21/servicos')
         .then(response => {
         this.servicos = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
         if (this.id){
            this.carregaTexto(this.id);
         }
         else{
          this.carregaTexto(this.servicos[0].itiId);
         }
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/22/servicoscallaction')
      .then(response => {
        this.servicocall = response.data;
      })
      .catch(error => {
        console.error(error);
      });

       

  },
}
</script>

<style>
  .hhss{
            display:none;
        }
  .sli p {
    color:#ede3e3  !important;
  }
</style>
