<template>
  <div>
    <section class="page-top-section" :style="{backgroundImage:'url(' + $imgURL + sobretopo[0].insInstitucionalImagens[0].itgUrlImagem + ')'}" v-if="sobretopo!=''">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h2  id="h2TituloTopo">{{ sobretopo[0].itiNome}}</h2>
              <p  id="pTextoTopo" v-html="sobretopo[0].itiReferencia"></p>
              <a :href="sobretopo[0].itiLinkBtn1" class="site-btn" style="color:#FFFFFF;">{{sobretopo[0].itiTextoBtn1}}</a>
            </div>
          </div>
        </div>
    </section>
    <!-- Page top section end  -->
    <!-- About section -->
    <section class="about-section spad" v-if="sobretop!=''">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img :src="$imgURL + sobretop[0].insInstitucionalImagens.filter(x=> x.insListaGaleriaInstImagens.lgiId == 11)[0].itgUrlImagem" alt="" />
          </div>
          <div class="col-lg-6">
            <div class="about-text">
              <h2  id="h2TituloTexto">{{ sobretop[0].itiNome }}</h2>
              <p  id="pTextTexto" v-html="sobretop[0].itiResumo1"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- About section end -->
    <!-- Milestones section -->
    <section class="milestones-section" :style="{backgroundImage:'url(' + $imgURL + sobretop[0].insInstitucionalImagens.filter(x=> x.insListaGaleriaInstImagens.lgiId == 12)[0].itgUrlImagem + ')', backgroundSize: 'cover'}"  v-if="sobretop!=''">
      <div class="container text-white">
        <div class="row">
          <div class="col-lg-3 col-sm-6" v-for="numero in numeros" :key="numero.itiId">
            <div class="milestone">
              <div class="milestone-text">
                <span>
                  {{ numero.itiNome }}
                </span><br>
                <h2 style="display:inline-flex;"> 
                  <VueJsCounter start="0" :end="numero.itiCampoExtra1" duration="8000" class="counter" style="font-size: 60px;font-weight: 300;line-height: 0.9;margin-bottom: 5px;color: #99CC33;"></VueJsCounter>{{ numero.itiCampoExtra2 }}
                </h2>
                <p style="color:#ffffff;">
                  {{ numero.itiReferencia }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Milestones section end -->
    <!-- Team section -->
    <section class="team-section spad">
      <div class="container">
        <div class="team-text">
          <h2>{{clientes[0].itiNome}}</h2>
        </div>
        <div class="row">
          <div style="width:140px;height:80px;text-align: center;margin: 10px;" v-for="cliente in clientes[0].insInstitucionalImagens.filter(x=> x.itgVisivel)" :key="cliente.itiId">
            <a :href="cliente.itgNome">
              <img style="width:140px;height:90px;" 
                :src="$imgURL + cliente.itgUrlImagem" alt="">
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- Team section end -->
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import VueJsCounter from 'vue-js-counter'
import { useHead } from '@vueuse/head';

export default {
  name: 'SobreView',
  components: {
    VueJsCounter
  },
  data: () => ({
    sobretopo: [],
    sobretop:[],
    numeros:[],
    clientes:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Sobre - Top Informática'
			})
		},
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/16/sobretopo')
      .then(response => {
        this.sobretopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/17/sobreatop')
      .then(response => {
        this.sobretop = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/18/numeros')
      .then(response => {
        this.numeros = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/19/clientes')
      .then(response => {
        this.clientes = response.data;
        // Ordenar as imagens dentro de cada clientes com base no campo 'ordem'
        this.clientes.forEach(cliente => {
          cliente.insInstitucionalImagens = cliente.insInstitucionalImagens
            .filter(imagem => imagem.itgVisivel)
            .sort((a, b) => {
              const valorA = parseInt(a.itgOrdem) || 0;
              const valorB = parseInt(b.itgOrdem) || 0;
              return valorA - valorB;
            });
        });

          console.log('this.clientes')
          console.log(this.clientes)
      })
      .catch(error => {
        console.error(error);
      });

      
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
