<template>
  <div>
<!-- Call to action section  -->
<section class="cta-section" >
    <div class="container das" v-if="callaction!=''">
        <div class="row">
            <div class="col-lg-9 d-flex align-items-center">
                <h2 runat="server" id="h2Call">{{callaction[0].itiNome}}</h2>
            </div>
            <div class="col-lg-3 text-lg-right">
                <a :href="callaction[0].itiLinkBtn1" target="_blank" runat="server" id="linkEquipe" class="site-btn sb-dark" style="padding:23px 18px !important;">{{callaction[0].itiTextoBtn1}}</a>
            </div>
        </div>
    </div>
</section>
<!-- Call to action section end  -->
<!-- Footer section -->
<footer class="footer-section spad" v-if="dados!=''" style="position: relative;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget about-widget">
                    <img src="/img/logo-light.png" alt="">
                    <p runat="server" v-html="dados[0].itiResumo1"></p>
                    <div class="footer-social" v-if="redes!=''">
                        <a v-if="redes[0].itiNome!='' && redes[0].itiNome!=null" :href="redes[0].itiNome" runat="server" target="_blank" id="linkFace"><i class="fa fa-facebook"></i></a>
                        <a v-if="redes[0].itiCampoExtra3!='' && redes[0].itiCampoExtra3!=null" :href="redes[0].itiCampoExtra3" runat="server" target="_blank" id="linkSkype"><i class="fa fa-whatsapp"></i></a>
                        <a v-if="redes[0].itiCampoExtra1!='' && redes[0].itiCampoExtra1!=null" :href="redes[0].itiCampoExtra1" runat="server" target="_blank" id="linkMail"><i class="fa fa-instagram"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget">
                    <h2 class="fw-title">Serviços</h2>
                    <ul>
                       <li v-for="servico in servicos" :key="servico.itiId">
                         <router-link :to="'/servicos/' + servico.itiId + '/' + servico.itiNome.replaceAll(' ','-').replaceAll('/','').replaceAll(',','') ">{{ servico.itiNome }}</router-link>
                      </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget">
                    <h2 class="fw-title">Institucional</h2>
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/sobre">Sobre</router-link></li>
                        <li><router-link to="/blog">Blog</router-link></li>
                        <li><router-link to="/login">Suporte</router-link></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-7">
                <div class="footer-widget">
                    <h2 class="fw-title">Contatos</h2>
                    <div class="footer-info-box">
                        <div class="fib-icon">
                            <img src="/img/icons/map-marker.png" alt="" class="">
                        </div>
                        <div class="fib-text">
                            <p runat="server" v-html="dados[0].itiResumo2">
                            </p>
                        </div>
                    </div>
                    <div class="footer-info-box">
                        <div class="fib-icon">
                            <img src="/img/icons/phone.png" alt="" class="">
                        </div>
                        <div class="fib-text">
                            <p runat="server" v-html="dados[0].itiDescricao1">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-buttom">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" style="height: 60px; text-align: center; padding-top: 20px; font-size: 12px;">
                    <a href="https://www.avancedigital.com.br" target="_blank" style="color: #ffffff; font-size: 12px;"><span style="color: #99CC33;">Avance</span> Digital</a>
                </div>
            </div>
        </div>
    </div>
</footer>
  <div v-if="dados!=''" class="whatsapp-fixo">
    <a :href="dados[0].itiReferencia" target="_blank">
        <img src="/images/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
    </a>
  </div>
<!-- Footer section end -->
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageFooter',
  components: {

  },
  data: () => ({
    dados: [],
    redes:[],
    servicos:[],
    callaction:[],
  }),
  methods: {

  },
  created() {  
    axios.get('/Process/InsInstitucional/institucional/15/rodape')
         .then(response => {
         this.dados = response.data;
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/29/redessociais')
         .then(response => {
         this.redes = response.data;
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/21/servicos')
         .then(response => {
         this.servicos = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);;
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/14/callaction')
         .then(response => {
         this.callaction = response.data;
         console.log('call')
         console.log(this.callaction)
       })
       .catch(error => {
         console.error(error);
       });
       
       
  }
}
</script>

<style scoped>
.menuTamanho {
  font-size: 20px;
  text-align: center;
}
</style>
