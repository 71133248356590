<template>
       <div>
        <!-- Hero section end  -->
          <v-carousel hide-delimiters class="tamCar" v-if="sliders.length > 0 && sliders[0].insInstitucionalImagens != ''">
            <v-carousel-item v-for="slider in sliders[0].insInstitucionalImagens" :key="slider.itgId">

              <v-sheet height="100%"
                :style="{ backgroundImage: 'url(' + $imgURL + slider.itgUrlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
                <div class="overlay"
                  style=" position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: #000000; opacity: .6; ">
                </div>
                <div class="d-flex fill-height justify-center align-center ">
                  <div class="text-h2">
                    <div class="col-md-11 text-center slider-item slider-text">
                      <h2 v-html="slider.itgResumo" style="font-size: 90px;font-weight: 300;"></h2>
                    </div>
                   </div>
  					     </div>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
      <!-- Services section  -->
      <section class="services-section" v-if="destaques.length > 0 && destaques[0].insInstitucionalImagens != ''">
          <div class="services-warp">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-4 col-md-6" v-for="destaque in destaques[0].insInstitucionalImagens" :key="destaque.itiId">
                          <div class="service-item">
                              <div class="si-head">
                                  <div class="si-icon">
                                      <img :src="$imgURL + destaque.itgUrlImagem" alt="">
                                  </div>
                                  <h5>{{ destaque.itgNome }}</h5>
                              </div>
                              <p v-html="destaque.itgResumo"></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    <!-- Services section end  -->
    <!-- Features section   -->
    <section class="features-section spad set-bg" :style="{backgroundImage:'url(' + $imgURL + ImgfundoServico[0].itgUrlImagem + ')'}" v-if="servicos.length > 0 && servicos[0].insInstitucionalImagens != '' && apresentacao!=''">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6" v-for="servico in servicos" :key="servico.itgId">
                    <div class="feature-box">
                        <img :src="$imgURL + servico.insInstitucionalImagens[0].itgUrlImagem" alt="">
                        <div class="fb-text">
                            <h5 style="height: 60px;">{{ servico.itiNome }}</h5>
                            <p v-html="servico.itiResumo2"></p>
                            <router-link :to="'/servicos/' + servico.itiId + '/' + servico.itiNome.replaceAll(' ','-').replaceAll('/','').replaceAll(',','') " class="fb-more-btn">Saiba mais</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Features section end  -->
    <!-- Clients section  -->
    <section class="clients-section spad" v-if="apresentacao!=''">
        <div class="container">
            <div class="client-text">
                <h2 >{{ apresentacao[0].itiNome }}</h2>
                <p v-html="apresentacao[0].itiResumo1"></p>
            </div>
            <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="5" :breakpoints="{768: { slidesPerView: 5 }, 0: { slidesPerView: 3 }}" class="carousel-testimony owl-carousel">
              <SwiperSlide v-for="par in ImgParceiros" :key="par.itgId">
                <div class="item" style="padding: 10px;min-height: 120px;">
                  <img :src="$imgURL + par.itgUrlImagem" alt="" style="width: 140px;height: 90px;">
                </div>
              </SwiperSlide>
            </Swiper>
        </div>
    </section>
    <!-- Clients section end  -->
    <!-- Video section  -->
    <section class="video-section spad" v-if="suporte!=''">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="client-text" style="font-size: 25px; text-align: left;">
                        <h2>{{suporte[0].itiNome}}</h2>
                        <p v-html="suporte[0].itiResumo1">
                        </p>
                        <br />
                        <a :href="suporte[0].itiLinkBtn1"  id="linkSuporte" class="site-btn">Contatar</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img :src="$imgURL + suporte[0].insInstitucionalImagens[0].itgUrlImagem"  />
                </div>
            </div>
        </div>
    </section>
      </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    sliders:[],
    destaques:[],
    servicos:[],
    apresentacao:[],
    ImgfundoServico:[],
    ImgParceiros:[],
    suporte:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Home - Top Informática'
			})
		},
  },
  created() {
    this.carregaMetas();
    axios.get('/Process/InsInstitucional/institucional/10/slider')
         .then(response => {
         this.sliders = response.data;
         // Ordenar as imagens dentro de cada slider com base no campo 'ordem'
          this.sliders.forEach(slider => {
            slider.insInstitucionalImagens = slider.insInstitucionalImagens.filter(x=> x.itgVisivel).sort((a, b) => {
              const valorA = parseInt(a.itgOrdem) || 0;
              const valorB = parseInt(b.itgOrdem) || 0;
              return valorA - valorB;
            });
          });
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/11/destaqueshome')
         .then(response => {
         this.destaques = response.data;
          // Ordenar as imagens dentro de cada destaques com base no campo 'ordem'
          this.destaques.forEach(destaques => {
            destaques.insInstitucionalImagens = destaques.insInstitucionalImagens.filter(x=> x.itgVisivel).sort((a, b) => {
              const valorA = parseInt(a.itgOrdem) || 0;
              const valorB = parseInt(b.itgOrdem) || 0;
              return valorA - valorB;
            });
          });
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/21/servicos')
         .then(response => {
         this.servicos = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/12/apresentacao')
         .then(response => {
         this.apresentacao = response.data;

         this.ImgfundoServico = this.apresentacao[0].insInstitucionalImagens;
         this.ImgParceiros = this.apresentacao[0].insInstitucionalImagens;

         this.ImgfundoServico = this.ImgfundoServico.filter(x=> x.insListaGaleriaInstImagens.lgiId == 7 && x.insListaGaleriaInstImagens.lgiVisivel);
         this.ImgParceiros =  this.ImgParceiros.filter(x=> x.insListaGaleriaInstImagens.lgiId == 8 && x.insListaGaleriaInstImagens.lgiVisivel).sort((a, b) => {
              const valorA = parseInt(a.itgOrdem) || 0;
              const valorB = parseInt(b.itgOrdem) || 0;
              return valorA - valorB;
            });
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/InsInstitucional/institucional/13/suportetecnico')
         .then(response => {
         this.suporte = response.data;
       })
       .catch(error => {
         console.error(error);
       });

       
       
  },
}
</script>

<style>
   .das {
            display: block !important;
        }

    .slider-text h2 p{
      font-size: 60px;
      color:#081624;
      padding:10px;
      display: inline-block;
      background: #99CC33;
      line-height: 1;
    }
</style>
