<template>
  <div>
    <section class="page-top-section"
      :style="{ backgroundImage: 'url(' + $imgURL + blogtopo[0].insInstitucionalImagens[0].itgUrlImagem + ')' }"
      v-if="blogtopo != ''">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2 id="h2TituloTopo">{{ blogtopo[0].itiNome }}</h2>
            <p id="pTextoTopo" v-html="blogtopo[0].itiReferencia"></p>
            <a :href="blogtopo[0].itiLinkBtn1" class="site-btn" style="color:#FFFFFF;">{{ blogtopo[0].itiTextoBtn1 }}</a>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog section  -->
    <section class="blog-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 sidebar order-2 order-lg-1">
            <div class="sb-widget">
              <h2 class="sb-title">Últimos Posts</h2>
              <div class="recent-post">
                <div class="rp-item" v-for="menu in blog" :key="menu.itiId">
                  <a href="#" @click.prevent="carregaTexto(menu.itiId)">
                    <img style="height:60px;width:100px" :src="$imgURL + menu.insInstitucionalImagens[0].itgUrlImagem"
                      alt="">
                  </a>
                  <div class="rp-text">
                    <a href="#" @click.prevent="carregaTexto(menu.itiId)">
                      <div>{{ menu.itiNome }}</div>
                    </a>
                    <div class="rp-date">{{ formattedDate(menu.itiData1) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sb-widget">
              <div class="info-box sli" v-if="servicocall != ''">
                <h3>{{ servicocall[0].itiNome }}</h3>
                <p id="pTextoSuporte" v-html="servicocall[0].itiResumo1"></p>
                <div class="footer-info-box">
                  <div class="fib-icon">
                    <img src="/img/icons/phone.png" alt="" class="">
                  </div>
                  <div class="fib-text sli">
                    <p id="pTextoSuporteBaixo" v-html="servicocall[0].itiResumo2">
                    </p>
                  </div>
                </div>
              </div>
              <a href="/login" class="site-btn w-100">Abrir Chamado</a>
            </div>
          </div>
          <div class="col-lg-8 order-1 order-lg-2" v-if="newb!=''">
            <div class="blog-post">
              <div class="blog-thumb" :style="{backgroundImage:'url(' + $imgURL + newb[0].insInstitucionalImagens[0].itgUrlImagem + ')'}">
                <div class="blog-date">{{ formattedDate(newb[0].itiData1) }}</div>
              </div>
              <h2>{{ newb[0].itiNome}}</h2>
              <p v-html="newb[0].itiResumo2"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'BlogView',
  setup() {
    const route = useRoute();
    const id = route.params.idBlog;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    blogtopo: [],
    servicocall: [],
    blog: [],
    newb: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Blog - Top Informática'
      })
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    carregaTexto(value) {
      this.newb = this.blog.filter(x => x.itiId == value)
      if (this.isMobile()) {
      this.scrollToTop();
    }
    },
    scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Isso adicionará uma animação de rolagem suave
    });
  },
  isMobile() {
    return window.innerWidth <= 768; // Você pode ajustar esse valor de acordo com suas necessidades
  }
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/23/blogtopo')
      .then(response => {
        this.blogtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/22/servicoscallaction')
      .then(response => {
        this.servicocall = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/24/blog')
      .then(response => {
        this.blog = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiData1 - b.itiData1).reverse();
        if (this.id){
            this.carregaTexto(this.id);
         }
         else{
          this.carregaTexto(this.blog[0].itiId);
         }

      })
      .catch(error => {
        console.error(error);
      });


  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
