<template>
  <div>
    <section class="page-top-section" :style="{backgroundImage:'url(' + $imgURL + contatotopo[0].insInstitucionalImagens[0].itgUrlImagem + ')'}" v-if="contatotopo!=''">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h2  id="h2TituloTopo">Enviar Chamado</h2>
              <p  id="pTextoTopo" v-html="contatotopo[0].itiReferencia"></p>
            </div>
          </div>
        </div>
    </section>
   <!-- Contact section   -->
   <section class="contact-section spad">
        <div class="container">
            <div class="row">
              <div class="col-lg-2">
                   
                  </div>
                <div class="col-lg-8">
                    <center><h3 style="padding-bottom:50px;font-size:21px;"  id="tituloh2">Chamado</h3></center>
                    <div class="contact-form">
                        <div class="row">
                            <div class="col-lg-6" style="display: none;">
                                <span>Nome da Empresa:</span>
                                <input type="text" v-model="nomeEmpresa" id="txtNomeEmpresa"/>
                            </div>
                            <div class="col-lg-6">
                                <span>Nome do Funcionário:</span>
                                <input type="text" v-model="nomeFuncionario" id="txtNomeFuncinario"/>
                            </div>
                            <div class="col-lg-6">
                                <span>Número da Máquina:</span>
                                <input type="text" v-model="numeroMaquina" id="txtNumeroMaquina"/>
                            </div>
                            <div class="col-lg-6">
                                <span><b style="font-size:10px;">Formatos aceitos:<br />.doc, .docx, .txt, .pdf, .png, .jpg, .jpeg, .gif e .bmp</b></span>
                                <v-file-input v-model="selectedFile" label="Anexar Arquivo (Opcional)" variant="underlined" @change="handleFileChange"
                                        prepend-icon="mdi-account-circle" accept=".doc,.docx,.txt,.pdf,.png,.jpg,.jpeg,.gif,.bmp"></v-file-input>
                            </div>
                            <div class="col-lg-12">
                                <span>Descreva o Problema:</span>
                                <textarea class="text-msg" style="border: 1px solid #000000;color: black;" v-model="mensagemProblema"></textarea>
                                <button class="site-btn" @click="EnviaEmail()" style="color:#ffffff">Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                   
                </div>
            </div>
        </div>
    </section>
    <!-- Contact section end  -->

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';

export default {
  name: 'ChamadoView',
  components: {
  },
  data: () => ({
    contatotopo: [],
    contato:[],
    nomeEmpresa:'',
    nomeFuncionario:'',
    numeroMaquina:'',
    mensagemProblema:'',
    clienteNome:'',
    selectedFile: null,
    _dadosEmail:{
      nomeDe: '',
      nomePara: "Top Informática",
      assunto: '',
      destinatario: '',
      emailResposta: '',
      textoEmail: '',
      nomeAnexo: '',
      anexo: '',
      tipoAnexo: ''
    }
  }),
  methods: {
    checkLogin() {
      let cvalue = this.$Cookies.get("_topinfcha");
      console.log('cvalue')
      console.log(cvalue)
      if (cvalue !== undefined) {
        this.clienteNome = cvalue;
      } else {
        this.$router.push('/Login');
      }

    },
    handleFileChange() {
      console.log(this.selectedFile); // Verifique o valor de this.selectedFile

      if (this.selectedFile && this.selectedFile[0]) { // Verifique se há um arquivo no array
        const file = this.selectedFile[0]; // Obtenha o primeiro arquivo do array
        this._dadosEmail.nomeAnexo = file.name;

        const reader = new FileReader();
        reader.onload = () => {
          this._dadosEmail.anexo = reader.result.split(",")[1]; // Conteúdo do arquivo em base64
          this._dadosEmail.tipoAnexo = file.type;

          // Você pode optar por enviar o email aqui ou fazer qualquer outra ação necessária
        };
        reader.readAsDataURL(file);
      }
    },
    EnviaEmail(){
     if (this.nomeFuncionario == '') {
        this.$mensagemAviso("Nome Funcionário Obrigatório.")
      }
      else if (this.mensagemProblema == '') {
        this.$mensagemAviso("Descrição do problema Obrigatório.")
      }
      else {

        let _mensagem = "Conta: " + this.clienteNome + "   Funcionário: " + this.nomeFuncionario + "   Máquina: " + this.numeroMaquina;
        _mensagem += "<br/><br/>";
        _mensagem += "<br/><Strong>Conta:</Strong> <br/>" + this.clienteNome;
        //_mensagem += "<br/><Strong>Nome da Empresa:</Strong>  " + this.nomeEmpresa;
        _mensagem += "<br/><Strong>Nome do Funcionário:</Strong>  " + this.nomeFuncionario;
        _mensagem += "<br/><Strong>Número da Máquina:</Strong>  " + this.numeroMaquina;
        _mensagem += "<br/><Strong>Descrição do problema:</Strong>  " + this.mensagemProblema;

        this._dadosEmail.nomeDe = this.clienteNome;
        this._dadosEmail.nomePara = "Top Informática",
        this._dadosEmail.assunto = "Novo Chamado - " + this.clienteNome + " - Top Informática",
        this._dadosEmail.destinatario = this.contato[0].itiCampoExtra2,
        this._dadosEmail.emailResposta = this.contato[0].itiCampoExtra2,
        this._dadosEmail.textoEmail = _mensagem,

        axios.post(`/Process/AdmControleSmtp/Enviamail`, this._dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              //this.nomeEmpresa = "";
              this.nomeFuncionario = "";
              this.numeroMaquina = "";
              this.mensagemProblema = "";
              this.selectedFile = null;
            }
          });
      }
    },
  },
  created() {

    this.checkLogin();

    axios.get('/Process/InsInstitucional/institucional/25/contatotopo')
      .then(response => {
        this.contatotopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/26/contato')
      .then(response => {
        this.contato = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
