
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Sobre from '../views/SobreView.vue'
import Servicos from '../views/ServicosView.vue'
import Blog from '../views/BlogView.vue'
import Contato from '../views/ContatoView.vue'
import Login from '../views/LoginView.vue'
import Chamado from '../views/ChamadoView.vue'


const routes = [
 { path: '/', name: 'Home', component: Home },
 { path: '/Sobre', name: 'Sobre', component: Sobre },
 { path: '/Servicos/:idServico?/:nomeServico?', name: 'Servicos', component: Servicos },
 { path: '/Blog/:idBlog?/:nomeBlog?', name: 'Blog', component: Blog },
 { path: '/Contato', name: 'Contato', component: Contato },
 { path: '/Login', name: 'Login', component: Login },
 { path: '/Chamado', name: 'Chamado', component: Chamado },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
